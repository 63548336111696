.title {
    text-align: left;
    font-weight: 700;
    font-size: 26px;
}

.titleCloseBtn {
    display: flex;
    justify-content: flex-end;
}

.titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 15px;
    cursor: pointer;
    margin-right: -5%;
}

.footer {
    /* flex: 5% 1; */
    display: flex;
    /* justify-content: end; */
    align-items: center;
    /* height: 36% !important; */
}

.footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: #61D8AA;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
}

#cancelBtn {
    background-color: #808080;
    color: white;
    border-radius: "10px",
}

#saveBtn {
    background-color: #53CB9D;
}

.footer {
    /* margin-top: 10%; */
}


/* .PhoneInputCountrySelect option{
    color:red;
    max-height: 200px;
  }
  select{
    max-height: 200px !important;
  } */

.PhoneInputCountry {
    margin-right: 5% !important;
}

.PhoneInputInput {
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
}

.PhoneInputInput:focus {
    outline: none !important;
}

.PhoneInputCountrySelectArrow {
    margin-left: .9em;
}

.PhoneInputCountryIcon {
    width: 2rem;
    height: 1.5rem;
}