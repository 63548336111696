@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'reader';
    src: local('reader'), url(assets/Fonts/reader-regular.ttf) format('truetype');
}

@font-face {
    font-family: 'reader';
    font-weight: 900;
    src: local('reader'), url(assets/Fonts/reader-bold.ttf) format('truetype');
}

@font-face {
    font-family: 'readerLight';
    src: local('readerLight'), url(assets/Fonts/reader-light.ttf) format('truetype');
}

@font-face {
    font-family: 'readerThin';
    src: local('readerThin'), url(assets/Fonts/reader-thin.ttf) format('truetype');
}

@font-face {
    font-family: 'readerPro';
    src: local('readerPro'), url(assets/Fonts/reader-medium-pro.ttf) format('truetype');
}


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body,
html {
    font-family: reader, sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


/* 
/ Track / */

::-webkit-scrollbar-track {
    background: #1e2828 !important;
}


/* / Handle / */

::-webkit-scrollbar-thumb {
    background: #888;
}


/* / Handle on hover / */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.lastcolumnSeparator .MuiDataGrid-columnSeparator--sideRight {
    display: none !important;
}

hr {
    border-color: #494949;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}