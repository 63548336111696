.MuiOutlinedInput-root:focus {
    border-radius: 50px;
}

.search_bar {
    /* padding: 5px; */
    width: "60%";
    padding-left: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid #E3E3E3;
    background: #FFFFFF;
    margin-left: 2px;
    border-radius: 16.5px;
}

.search_bar:focus-visible {
    outline: none;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    outline: 0px !important;
    outline-offset: 0px !important;
    box-shadow: none !important;
}

[type='text']:hover {
    border-color: red!important;
}

.search_bar:focus {
    outline: none;
}

.MuiTextField-root .MuiInputBase-root:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0px !important;
}